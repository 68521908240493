// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.qa.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    version: '2.000.0',
    production: false,
    errMsg: 'Failed to complete. Please try again.',
    partner: false,
    config: {
        "stage": "qa",
        "appName": "custom-dashboard-ui",
        "appDisplayName":"75F",
        "caretakerUrl": "https://caretaker.qa.service.75f.io/api/v1",
        "haystackUrl": "https://silo.qa.service.75f.io/",
        "tagsUrl": "https://silo.qa.service.75f.io/v2/sitePointTags/",
        "dashboardServiceUrl": "https://table-maker-api-75f-service-qa.azurewebsites.net/",
        "tableMakerUrl": "https://table-maker-api.qa.service.75f.io/"
    }
};